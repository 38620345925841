import React from 'react'

import Header from '../components/start/Header'
import Registr from '../components/next/Registr'



function Registration() {
  return (
    <>
    <Header/>
    <Registr/>
    </>
  )
}

export default Registration