import React from 'react'

import Header from '../components/start/Header'
import Footer from '../components/start/Footer'
import Blog from '../components/blog/Blog'





function BlogPage() {
  return (
    <>
    <Header/>
    <Blog/>
    <Footer/>
    </>
  )
}

export default BlogPage