import React from 'react'

import Header from '../components/start/Header'
import Footer from '../components/start/Footer'

import Test from '../components/test/Test'



function ContactPage() {
  return (
    <>
    <Header/>
    <Test/>
    <Footer/>
    </>
  )
}

export default ContactPage