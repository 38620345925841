// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container-login {
    padding: 20px; 
    border-radius: 10px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
    
    
    
  }
  .form-red-user{
   width: 400px;
  }

  @media (max-width: 47.937rem) {
    .form-container-login {
      margin-top: 3rem;
    }
    .form-red-user{
      width: 250px;
     }
  }

.button{
    width: 100%;
    border-radius: 25px;
  }


  .navbar-links{
    color: var(--white);
    font-size: rem(16);
    font-weight: 600;
    letter-spacing: rem(2);
    display: inline-block;
  }
  .navbar-links::after{
        content: '';
        display: block;
        width: 0px;
        height: rem(2);
        color: var(--primarycolor);
        transition: all 350ms;
        border-radius: rem(5);
    }

  .navbar-links:hover{
        color: var(--primarycolor);
        
    }
  
.login:focus{
  box-shadow: none;
}

.error-message{
  color: rgb(255, 50, 50);
  margin-left: 1rem;
  margin-top: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/css/reg.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;;;;EAInB;EACA;GACC,YAAY;EACb;;EAEA;IACE;MACE,gBAAgB;IAClB;IACA;MACE,YAAY;KACb;EACH;;AAEF;IACI,WAAW;IACX,mBAAmB;EACrB;;;EAGA;IACE,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,sBAAsB;IACtB,qBAAqB;EACvB;EACA;QACM,WAAW;QACX,cAAc;QACd,UAAU;QACV,cAAc;QACd,0BAA0B;QAC1B,qBAAqB;QACrB,qBAAqB;IACzB;;EAEF;QACM,0BAA0B;;IAE9B;;AAEJ;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".form-container-login {\n    padding: 20px; \n    border-radius: 10px; \n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-top: 10rem;\n    \n    \n    \n  }\n  .form-red-user{\n   width: 400px;\n  }\n\n  @media (max-width: 47.937rem) {\n    .form-container-login {\n      margin-top: 3rem;\n    }\n    .form-red-user{\n      width: 250px;\n     }\n  }\n\n.button{\n    width: 100%;\n    border-radius: 25px;\n  }\n\n\n  .navbar-links{\n    color: var(--white);\n    font-size: rem(16);\n    font-weight: 600;\n    letter-spacing: rem(2);\n    display: inline-block;\n  }\n  .navbar-links::after{\n        content: '';\n        display: block;\n        width: 0px;\n        height: rem(2);\n        color: var(--primarycolor);\n        transition: all 350ms;\n        border-radius: rem(5);\n    }\n\n  .navbar-links:hover{\n        color: var(--primarycolor);\n        \n    }\n  \n.login:focus{\n  box-shadow: none;\n}\n\n.error-message{\n  color: rgb(255, 50, 50);\n  margin-left: 1rem;\n  margin-top: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
