import React from 'react'

import Header from '../components/start/Header'
import Login from '../components/next/Login'



function LoginPage() {
  return (
    <>
    <Header/>
    <Login/>
    </>
  )
}

export default LoginPage