// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-svg{
    color: hsl(0, 0%, 78%);
    padding-left: 2rem;
}

.main-link-to{
    text-decoration: none;
    color: hsl(0, 0%, 78%);
}

.main-link-to:hover{
color: white;
}

.faq-head-text{
    color: white;
    font-size: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/css/start.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;AACA,YAAY;AACZ;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".faq-svg{\n    color: hsl(0, 0%, 78%);\n    padding-left: 2rem;\n}\n\n.main-link-to{\n    text-decoration: none;\n    color: hsl(0, 0%, 78%);\n}\n\n.main-link-to:hover{\ncolor: white;\n}\n\n.faq-head-text{\n    color: white;\n    font-size: 3rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
