// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-text-header{
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: 500;
}

.big-cifra{
    color: white;
    font-size: 2.2rem;
    font-weight: bolder;
}
`, "",{"version":3,"sources":["webpack://./src/css/about.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".about-text-header{\n    text-transform: uppercase;\n    font-size: 3rem;\n    font-weight: 500;\n}\n\n.big-cifra{\n    color: white;\n    font-size: 2.2rem;\n    font-weight: bolder;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
