// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#expert-container{
    margin-top: 6rem;
}

.mentor-status{
    background-color: hsl(179, 93%, 44%);
    padding: 0.2rem 0.4rem;
    color: white;
    border-radius: 0.8rem;
    display: flex;
    font-weight: bold;
}
.mentor-status1{
    display: flex;
}
.about-me{
    margin-bottom: 3rem;
}

@media (max-width: 50.625em) {
    .mentor-status1{
        width: 100%;
        align-items: center !important;
        justify-content: center !important;
        }
        .mentor-status1{
    
            align-items: center !important;
            justify-content: center !important;  
        }
}`, "",{"version":3,"sources":["webpack://./src/css/mentors.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;IACtB,YAAY;IACZ,qBAAqB;IACrB,aAAa;IACb,iBAAiB;AACrB;AACA;IACI,aAAa;AACjB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,WAAW;QACX,8BAA8B;QAC9B,kCAAkC;QAClC;QACA;;YAEI,8BAA8B;YAC9B,kCAAkC;QACtC;AACR","sourcesContent":["#expert-container{\n    margin-top: 6rem;\n}\n\n.mentor-status{\n    background-color: hsl(179, 93%, 44%);\n    padding: 0.2rem 0.4rem;\n    color: white;\n    border-radius: 0.8rem;\n    display: flex;\n    font-weight: bold;\n}\n.mentor-status1{\n    display: flex;\n}\n.about-me{\n    margin-bottom: 3rem;\n}\n\n@media (max-width: 50.625em) {\n    .mentor-status1{\n        width: 100%;\n        align-items: center !important;\n        justify-content: center !important;\n        }\n        .mentor-status1{\n    \n            align-items: center !important;\n            justify-content: center !important;  \n        }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
