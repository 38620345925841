// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-cont{
    margin-top: 10rem;
}
@media (max-width: 47.937rem) {
    .contact-cont{
        margin-top: -7rem;
    }
}
.cont-text{
    display: flex;
}
.cont-svg{
    margin-left: 1rem
}

.contact-head-text{
    text-transform: uppercase;
    color: white;
    font-size: 3rem;
}
.description-section-contact{
    display: flex; 
    align-items: center;
    justify-content: center;
}

.text-description-contact{
 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);

}`, "",{"version":3,"sources":["webpack://./src/css/contact.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI;QACI,iBAAiB;IACrB;AACJ;AACA;IACI,aAAa;AACjB;AACA;IACI;AACJ;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;;IAEI,2CAA2C;;AAE/C","sourcesContent":[".contact-cont{\n    margin-top: 10rem;\n}\n@media (max-width: 47.937rem) {\n    .contact-cont{\n        margin-top: -7rem;\n    }\n}\n.cont-text{\n    display: flex;\n}\n.cont-svg{\n    margin-left: 1rem\n}\n\n.contact-head-text{\n    text-transform: uppercase;\n    color: white;\n    font-size: 3rem;\n}\n.description-section-contact{\n    display: flex; \n    align-items: center;\n    justify-content: center;\n}\n\n.text-description-contact{\n \n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
