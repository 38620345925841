// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-section{
margin: 2rem 1rem ;
background-color: hsl(217, 10%, 25%);
padding: 1.5rem 2.5rem 2rem;
border-radius: 1rem;
}

.button-form{
    margin: auto;
    display: flex;
    justify-content: flex-end;
}

.about-test{
    color: white;
}
#about-test-button{
    padding: 0.7rem 1.4rem;
}
#about-test-button-group{
    margin-top: 2rem;
}

#faq2{
    padding: 0;
    margin-top: 4rem;
}

#test-finale-results{
    margin-top: -3rem;
}

.more-ifo{
    color: hsl(179, 93%, 44%);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/css/account.css"],"names":[],"mappings":"AAAA;AACA,kBAAkB;AAClB,oCAAoC;AACpC,2BAA2B;AAC3B,mBAAmB;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":[".results-section{\nmargin: 2rem 1rem ;\nbackground-color: hsl(217, 10%, 25%);\npadding: 1.5rem 2.5rem 2rem;\nborder-radius: 1rem;\n}\n\n.button-form{\n    margin: auto;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.about-test{\n    color: white;\n}\n#about-test-button{\n    padding: 0.7rem 1.4rem;\n}\n#about-test-button-group{\n    margin-top: 2rem;\n}\n\n#faq2{\n    padding: 0;\n    margin-top: 4rem;\n}\n\n#test-finale-results{\n    margin-top: -3rem;\n}\n\n.more-ifo{\n    color: hsl(179, 93%, 44%);\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
