// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-container{
    margin-top: 12rem;
}
@media (max-width: 47.937rem) {
    .profile-container{
        margin-top: 3rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/profile.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".profile-container{\n    margin-top: 12rem;\n}\n@media (max-width: 47.937rem) {\n    .profile-container{\n        margin-top: 3rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
