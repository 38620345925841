// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe{
    width: 100%;
    height: 600px;
}

@media (max-width: 70rem) {
    iframe{
        width: 100%;
        height: 400px;
    }
}

@media (max-width: 47.937rem) {
    iframe{
        width: 100%;
        height: 280px;
    }
}



.post-single-cont{
    display: grid;
    padding: 0;
    gap: 1rem;
}

.post-single{
    padding: 1rem 0;
    background-color: hsl(217, 10%, 25%);
    border-radius: 1rem;
    margin: 12rem 0 0.9rem 0;
    display: flex;
    flex-direction: row;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1rem)); 

}

.no-padding{
    padding: 0;
}

@media (max-width: 47.937rem) {
    .post-single{
        margin-top: 3rem;
    }
}

#poduct-name2{
    color: hsl(179, 93%, 44%);
}

.single-post-text{
    padding: 1rem 1rem;
    background-color: hsl(217, 10%, 25%);
    border-radius: 1rem;
    margin: 2rem 0 2rem 0;
    display: flex;
    flex-direction: row;
    color: hsl(0, 0%, 78%);
}

.html-text{
    max-width: 100%;
    text-wrap: wrap;
    overflow-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./src/css/singlepost.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI;QACI,WAAW;QACX,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,aAAa;IACjB;AACJ;;;;AAIA;IACI,aAAa;IACb,UAAU;IACV,SAAS;AACb;;AAEA;IACI,eAAe;IACf,oCAAoC;IACpC,mBAAmB;IACnB,wBAAwB;IACxB,aAAa;IACb,mBAAmB;IACnB,6DAA6D;;AAEjE;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,oCAAoC;IACpC,mBAAmB;IACnB,qBAAqB;IACrB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":["iframe{\n    width: 100%;\n    height: 600px;\n}\n\n@media (max-width: 70rem) {\n    iframe{\n        width: 100%;\n        height: 400px;\n    }\n}\n\n@media (max-width: 47.937rem) {\n    iframe{\n        width: 100%;\n        height: 280px;\n    }\n}\n\n\n\n.post-single-cont{\n    display: grid;\n    padding: 0;\n    gap: 1rem;\n}\n\n.post-single{\n    padding: 1rem 0;\n    background-color: hsl(217, 10%, 25%);\n    border-radius: 1rem;\n    margin: 12rem 0 0.9rem 0;\n    display: flex;\n    flex-direction: row;\n    grid-template-columns: repeat(auto-fill, minmax(18rem, 1rem)); \n\n}\n\n.no-padding{\n    padding: 0;\n}\n\n@media (max-width: 47.937rem) {\n    .post-single{\n        margin-top: 3rem;\n    }\n}\n\n#poduct-name2{\n    color: hsl(179, 93%, 44%);\n}\n\n.single-post-text{\n    padding: 1rem 1rem;\n    background-color: hsl(217, 10%, 25%);\n    border-radius: 1rem;\n    margin: 2rem 0 2rem 0;\n    display: flex;\n    flex-direction: row;\n    color: hsl(0, 0%, 78%);\n}\n\n.html-text{\n    max-width: 100%;\n    text-wrap: wrap;\n    overflow-wrap: break-word;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
