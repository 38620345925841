import React from 'react'

import Header from '../components/start/Header'
import Footer from '../components/start/Footer'
import Events from '../components/blog/Events'





function EventPage() {
  return (
    <>
    <Header/>
    <Events/>
    <Footer/>
    </>
  )
}

export default EventPage