import React from 'react'

import Header from '../components/start/Header'
import Footer from '../components/start/Footer'
import Red from '../components/profile/Red'



function ContactPage() {
  return (
    <>
    <Header/>
    <Red/>
    <Footer/>
    </>
  )
}

export default ContactPage