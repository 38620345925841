// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  font-size: 16px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  line-height: 1.5;
}

/* width */
*, *::after, *::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: var(--outfit);
  background-color: var(--Backgroundcolor);
  overflow-x: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

li {
  list-style: none;
}

img {
  width: 100%;
}

section, footer {
  width: 100%;
  max-width: 93.75rem;
  padding: 1rem 7.5rem;
}

ion-icon {
  font-size: 2.25rem;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/css/global/_boilerplate.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,sBAAA;EACA,uBAAA;EACA,gBAAA;AADJ;;AAIA,UAAA;AAIA;EACI,mBAAA;EACA,SAAA;EACA,UAAA;EACA,uBAAA;AAJJ;;AAOA;EACI,0BAAA;EACA,wCAAA;EACA,kBAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAJJ;;AAOA;EACI,gBAAA;AAJJ;;AAOA;EACI,WAAA;AAJJ;;AAOA;EACI,WAAA;EACA,mBAAA;EACA,oBAAA;AAJJ;;AAOA;EACI,kBAAA;EACA,eAAA;AAJJ","sourcesContent":["@use \"../util\" as *;\n\nhtml{\n    font-size: 16px;\n    box-sizing: border-box;\n    scroll-behavior: smooth;\n    line-height: 1.5;\n\n}\n/* width */\n\n\n\n*,*::after,*::before{\n    box-sizing: inherit;\n    margin: 0;\n    padding: 0;\n    scroll-behavior: smooth;\n}\n\nbody{\n    font-family: var(--outfit);\n    background-color: var(--Backgroundcolor);\n    overflow-x: hidden;\n    margin: 0 auto;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\nli{\n    list-style: none;\n}\n\nimg{\n    width: 100%;\n}\n\nsection,footer{\n    width: 100%;\n    max-width: rem(1500);\n    padding: 1rem rem(120);\n}\n\nion-icon{\n    font-size: rem(36);\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
