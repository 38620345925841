const questions = [
    { id: 0, title: '!', type: 'about', 
    text: 'Первый блок вопросов нацелен на определение твоего темперамента. Тебе будет предложено несколько вопросов об особенностях твоего поведения в различных условиях и ситуациях. Это не испытание ума или способностей, поэтому нет ответов хороших или плохих. Мы заинтересованы в правдивых ответах. Содержание отдельных вопросов не анализируется, важен только итоговый результат. На вопрос можно ответить "да" или "нет". ', 
    },
    { id: 1, title: '1', type: 'yesno', 
    text: 'Часто ли ты испытываешь тягу к новым впечатлениям, к тому чтобы отвлечься, испытать сильные ощущения?', 
    options: ['да ', 'нет '] },
    { id: 2, title: '2', type: 'yesno', 
    text: 'Часто ли ты чувствуешь, что нуждаешься в друзьях, которые могут тебя понять, одобрить или посочувствовать?', 
    options: ['да', 'нет'] },
    { id: 3, title: '3', type: 'yesno', 
    text: 'Считаешь ли ты себя беззаботным человеком?', 
    options: ['да ', 'нет '] },
    { id: 4, title: '4', type: 'yesno', 
    text: 'Очень ли трудно тебе отказываться от своих намерений?', 
    options: ['да', 'нет'] },
    { id: 5, title: '5', type: 'yesno', 
    text: 'Обдумываешь ли ты свои дела не спеша и предпочитаешь подождать, прежде чем действовать?', 
    options: ['да ', 'нет '] },
    { id: 6, title: '6', type: 'yesno', 
    text: 'Всегда ли ты сдерживаешь свои обещания, даже если тебе это невыгодно?', 
    options: ['да', 'нет'] },
    { id: 7, title: '7', type: 'yesno', 
    text: 'Часто ли у тебя бывают спады и подъемы настроения?', 
    options: ['да ', 'нет '] },
    { id: 8, title: '8', type: 'yesno', 
    text: 'Быстро ли ты обычно действуешь и говоришь, не затрачиваешь ли много времени на обдумывание?', 
    options: ['да', 'нет'] },
    { id: 9, title: '9', type: 'yesno', 
    text: 'Возникало ли у тебя когда-нибудь чувство, что ты несчастен, хотя никакой серьезной причины на это не было?', 
    options: ['да ', 'нет '] },
    { id: 10, title: '10', type: 'yesno', 
    text: 'Верно ли, что "на спор" ты способен решиться на все?', 
    options: ['да', 'нет'] },
    { id: 11, title: '11', type: 'yesno', 
    text: 'Смущаешься ли ты, когда хочешь познакомиться с человеком противоположного пола, который тебе симпатичен?', 
    options: ['да ', 'нет '] },
    { id: 12, title: '12', type: 'yesno', 
    text: 'Бывает ли когда-нибудь, что, разозлившись, ты выходишь из себя?', 
    options: ['да', 'нет'] },
    { id: 13, title: '13', type: 'yesno', 
    text: 'Часто ли действуешь необдуманно, под влиянием момента?', 
    options: ['да ', 'нет '] },
    { id: 14, title: '14', type: 'yesno', 
    text: 'Часто ли тебя беспокоят мысли о том, что тебе не следовало чего-либо делать или говорить?', 
    options: ['да', 'нет'] },
    { id: 15, title: '15', type: 'yesno', 
    text: 'Предпочитаешь ли ты чтение книг встречам с людьми?', 
    options: ['да ', 'нет '] },
    { id: 16, title: '16', type: 'yesno', 
    text: 'Верно ли, что тебя легко задеть?', 
    options: ['да', 'нет'] },
    { id: 17, title: '17', type: 'yesno', 
    text: 'Любишь ли ты часто бывать в компании?', 
    options: ['да ', 'нет '] },
    { id: 18, title: '18', type: 'yesno', 
    text: 'Бывают ли у тебя такие мысли, которыми тебе не хотелось делиться с другими людьми?', 
    options: ['да', 'нет'] },
    { id: 19, title: '19', type: 'yesno', 
    text: 'Верно ли, что иногда ты настолько полон энергии, что все горит в руках, а иногда ты чувствуешь сильную вялость?', 
    options: ['да ', 'нет '] },
    { id: 20, title: '20', type: 'yesno', 
    text: 'Стараешься ли ты ограничить круг своих знакомств небольшим числом самых близких людей?', 
    options: ['да', 'нет'] },
    { id: 21, title: '21', type: 'yesno', 
    text: 'Много ли ты мечтаешь?', 
    options: ['да ', 'нет '] },
    { id: 22, title: '22', type: 'yesno', 
    text: 'Когда на тебя кричат, отвечаешь ли тем же?', 
    options: ['да', 'нет'] },
    { id: 23, title: '23', type: 'yesno', 
    text: 'Считаешь ли ты свои привычки хорошими?', 
    options: ['да ', 'нет '] },
    { id: 24, title: '24', type: 'yesno', 
    text: 'Часто ли у тебя появляется чувство, что ты чем-то виноват?', 
    options: ['да', 'нет'] },
    { id: 25, title: '25', type: 'yesno', 
    text: 'Способен ли ты  иногда дать волю своим чувств и беззаботно развлечься с веселой компанией?', 
    options: ['да ', 'нет '] },
    { id: 26, title: '26', type: 'yesno', 
    text: 'Можно ли сказать, что часто у тебя нервы бывают натянуты до предела?', 
    options: ['да', 'нет'] },
    { id: 27, title: '27', type: 'yesno', 
    text: 'Считаешь ли ты себя человеком веселым и живым?', 
    options: ['да ', 'нет '] },
    { id: 28, title: '28', type: 'yesno', 
    text: 'После того, как дело сделано, часто ли ты мысленно возвращаешься к нему и думаешь, что мог бы сделать лучше?', 
    options: ['да', 'нет'] },
    { id: 29, title: '29', type: 'yesno', 
    text: 'Чувствуешь ли ты себя неспокойно, находясь в большой компании?', 
    options: ['да ', 'нет '] },
    { id: 30, title: '30', type: 'yesno', 
    text: 'Бывает ли, что ты передаешь слухи?', 
    options: ['да', 'нет'] },
    { id: 31, title: '31', type: 'yesno', 
    text: 'Бывает ли, что тебе не спится из-за того, что в голову лезут разные мысли?', 
    options: ['да ', 'нет '] },
    { id: 32, title: '32', type: 'yesno', 
    text: 'Что ты предпочитаешь, если хочешь что-либо узнать: найти это в книге или спросить у друзей?', 
    options: ['да', 'нет'] },
    { id: 33, title: '33', type: 'yesno', 
    text: 'Бывают ли у тебя сильные сердцебиения?', 
    options: ['да ', 'нет '] },
    { id: 34, title: '34', type: 'yesno', 
    text: 'Нравится ли тебе работа, требующая сосредоточения?', 
    options: ['да', 'нет'] },
    { id: 35, title: '35', type: 'yesno', 
    text: 'Бывают ли у тебя приступы дрожи?', 
    options: ['да ', 'нет '] },
    { id: 36, title: '36', type: 'yesno', 
    text: 'Всегда ли ты говоришь только правду?', 
    options: ['да', 'нет'] },
    { id: 37, title: '37', type: 'yesno', 
    text: 'Бывает ли тебе  неприятно находиться в компании, где все подшучивают друг над другом?', 
    options: ['да ', 'нет '] },
    { id: 38, title: '38', type: 'yesno', 
    text: 'Раздражителен ли ты?', 
    options: ['да', 'нет'] },
    { id: 39, title: '39', type: 'yesno', 
    text: 'Нравится ли тебе работа, требующая быстрого действия?', 
    options: ['да ', 'нет '] },
    { id: 40, title: '40', type: 'yesno', 
    text: 'Верно ли, что тебе часто не дают покоя мысли о разных неприятностях и "ужасах", которые могли бы произойти, хотя все кончилось благополучно?', 
    options: ['да', 'нет'] },
    { id: 41, title: '41', type: 'yesno', 
    text: 'Верно ли, что ты нетороплив в движениях и несколько медлителен?', 
    options: ['да ', 'нет '] },
    { id: 42, title: '42', type: 'yesno', 
    text: 'Опаздывал ли ты когда-нибудь на работу или встречу с кем-то?', 
    options: ['да', 'нет'] },
    { id: 43, title: '43', type: 'yesno', 
    text: 'Часто ли тебе снятся кошмары?', 
    options: ['да ', 'нет '] },
    { id: 44, title: '44', type: 'yesno', 
    text: 'Верно ли что ты так любишь поговорить, что не упускаешь любого удобного случая побеседовать с новым человеком?', 
    options: ['да', 'нет'] },
    { id: 45, title: '45', type: 'yesno', 
    text: 'Беспокоят ли тебя какие-либо боли?', 
    options: ['да ', 'нет '] },
    { id: 46, title: '46', type: 'yesno', 
    text: 'Огорчился бы ты, если бы не смог долго видеться с друзьями?', 
    options: ['да', 'нет'] },
    { id: 47, title: '47', type: 'yesno', 
    text: 'Можешь ли ты назвать себя нервным человеком?', 
    options: ['да ', 'нет '] },
    { id: 48, title: '48', type: 'yesno', 
    text: 'Есть ли среди твоих знакомых такие, которые тебе явно не нравятся?', 
    options: ['да', 'нет'] },
    { id: 49, title: '49', type: 'yesno', 
    text: 'Мог бы ты сказать, что ты уверенный в себе человек?', 
    options: ['да ', 'нет '] },
    { id: 50, title: '50', type: 'yesno', 
    text: 'Легко ли тебя задевает критика ваших недостатков, или твоей работы?', 
    options: ['да', 'нет'] },
    { id: 51, title: '51', type: 'yesno', 
    text: 'Трудно ли тебе получить настоящее удовольствие от мероприятий, в которых участвует много народу?', 
    options: ['да ', 'нет '] },
    { id: 52, title: '52', type: 'yesno', 
    text: 'Беспокоит ли тебя чувство, что ты чем-то хуже других?', 
    options: ['да', 'нет'] },
    { id: 53, title: '53', type: 'yesno', 
    text: 'Сумел бы ты внести оживление в скучную компанию?', 
    options: ['да ', 'нет '] },
    { id: 54, title: '54', type: 'yesno', 
    text: 'Бывает ли, что ты говоришь о вещах, в которых совсем не разбираешься?', 
    options: ['да', 'нет'] },
    { id: 55, title: '55', type: 'yesno', 
    text: 'Беспокоишься ли ты о своем здоровье?', 
    options: ['да ', 'нет '] },
    { id: 56, title: '56', type: 'yesno', 
    text: 'Любишь ли ты подшутить над другими?', 
    options: ['да', 'нет'] },
    { id: 57, title: '57', type: 'yesno', 
    text: 'Страдаешь ли ты бессонницей?', 
    options: ['да ', 'нет '] },
    { id: 58, title: '!', type: 'about', 
    text: 'Второй блок вопросов нацелен на изучение поведения в конфликтных ситуациях. В каждой паре выберите то суждение, которое наиболее точно описывает твое типичное поведение в конфликтной ситуации.', 
    },
    { id: 59, title: '58', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Иногда я предоставляю другим возможность взять на себя ответственность за решение спорного вопроса.',
    'Чем обсуждать то, в чём мы расходимся, я стараюсь обратить внимание на то, в чем согласны мы оба.'] },
    { id: 60, title: '59', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я стараюсь найти компромиссное решение.',
    'Я пытаюсь уладить дело с учетом всех интересов другого и моих собственных.'] },
    { id: 61, title: '60', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Обычно я настойчиво стремлюсь добиться своего.',
    'Я стараюсь успокоить другого и стремлюсь, главным образом, сохранить наши отношения.'] },
    { id: 62, title: '61', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я стараюсь найти компромиссное решение.',
    'Иногда я жертвую своими собственными интересами ради интересов другого человека.'] },
    { id: 63, title: '62', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Улаживая спорную ситуацию, я всё время стараюсь найти поддержку у другого',
    'Я стараюсь сделать всё, чтобы избежать бесполезной напряжённости.'] },
    { id: 64, title: '63', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я стараюсь избежать возникновения неприятностей для себя.',
    'Я стараюсь добиться своего.'] },
    { id: 65, title: '64', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я стараюсь отложить решение сложного вопроса с тем, чтобы со временем решить его окончательно.',
    'Я считаю возможным в чём-то уступить, чтобы добиться чего-то другого.'] },
    { id: 66, title: '65', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Обычно я настойчиво стремлюсь добиться своего.',
    'Первым делом я стараюсь ясно определить то, в чём состоят все затронутые интересы и спорные вопросы.'] },
    { id: 67, title: '66', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Думаю, что не всегда стоит волноваться из-за каких-то возникающих разногласий.',
    'Я предпринимаю усилия, чтобы добиться своего.'] },
    { id: 68, title: '67', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я твердо стремлюсь достичь своего.',
    'Я пытаюсь найти компромиссное решение.'] },
    { id: 69, title: '68', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Первым делом я стараюсь ясно определить то, в чем состоят все затронутые интересы и спорные вопросы.',
    'Я стараюсь успокоить другого и стремлюсь, главным образом, сохранить наши отношения.'] },
    { id: 70, title: '69', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Зачастую я избегаю занимать позицию, которая может вызвать споры.',
    'Я даю возможность другому в чём-то остаться при своем мнении, если он также идет навстречу мне.'] },
    { id: 71, title: '70', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я предлагаю среднюю позицию.',
    'Я пытаюсь убедить другого в преимуществах своей позиции.'] },
    { id: 72, title: '71', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я сообщаю другому свою точку зрения и спрашиваю о его взглядах.',
    'Я пытаюсь показать другому логику и преимущество своих взглядов.'] },
    { id: 73, title: '72', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я мог бы постараться удовлетворить чувства другого человека и сохранить наши отношения.',
    'Я стараюсь сделать всё необходимое, чтобы избежать напряженности.'] },
    { id: 74, title: '73', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я стараюсь не задеть чувства другого.',
    'Я пытаюсь убедить другого в преимуществах моей позиции.'] },
    { id: 75, title: '74', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Обычно я настойчиво стремлюсь добиться своего.',
    'Я стараюсь сделать всё, чтобы избежать бесполезной напряжённости.'] },
    { id: 76, title: '75', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Если это сделает другого счастливым, я дам ему возможность настоять на своём.',
    'Я дам возможность другому в чём-то оставаться при своём мнении, если он также идет мне навстречу.'] },
    { id: 77, title: '76', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Первым делом я стараюсь ясно определить то, в чем состоят все затронутые интересы и спорные вопросы.',
    'Я стараюсь отложить решение сложного вопроса с тем, чтобы со временем решить его окончательно'] },
    { id: 78, title: '77', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я пытаюсь немедленно разрешить наши разногласия.',
    'Я стараюсь найти наилучшее сочетание выгод и потерь для нас обоих.'] },
    { id: 79, title: '78', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Ведя переговоры, я стараюсь быть внимательным к желаниям другого.',
    'Я всегда склоняюсь к прямому обсуждению проблемы.'] },
    { id: 80, title: '79', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я пытаюсь найти позицию, которая находится посередине между моей и той, которая отстаивается другим.',
    'Я отстаиваю свои желания.'] },
    { id: 81, title: '80', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Как правило, я озабочен тем, чтобы удовлетворить желания каждого из нас.',
    'Иногда я предоставляю другим возможность взять на себя ответственность за решение спорного вопроса.'] },
    { id: 82, title: '81', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Если позиция другого кажется ему очень важной, я постараюсь пойти навстречу его желаниям.',
    'Я стараюсь убедить другого в необходимости прийти к компромиссу.'] },
    { id: 83, title: '82', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я пытаюсь показать другому логику и преимущество своих взглядов.',
    'Ведя переговоры, я стараюсь быть внимательным к желаниям другого.'] },
    { id: 84, title: '83', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я предлагаю среднюю позицию.',
    'Я почти всегда озабочен тем, чтобы удовлетворить желания каждого.'] },
    { id: 85, title: '84', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Зачастую я избегаю занимать позицию, которая может вызвать споры.',
    'Если это сделает другого счастливым, я дам ему возможность настоять на своём.'] },
    { id: 86, title: '85', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Обычно я настойчиво стремлюсь добиться своего.',
    'Улаживая спорную ситуацию, я обычно стараюсь найти поддержку у другого.'] },
    { id: 87, title: '86', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я предлагаю среднюю позицию.',
    'Думаю, что не всегда стоит волноваться из-за каких-то возникающих разногласий.'] },
    { id: 88, title: '87', type: 'yesno', 
    text: 'Рассмотрим ситуацию, когда ты обнаруживаешь, что твои желания отличаются от желаний другого человека, Как ты обычно реагируешь? ', 
    options: ['Я стараюсь не задеть чувств другого.',
    'Я всегда занимаю такую позицию в спорном вопросе, чтобы мы могли совместно с другим заинтересованным человеком добиться успеха.'] },
    { id: 89, title: '!', type: 'about', 
    text: 'Третий блок вопросов нацелен на выявление предпочитаемого стиля деятельности. Точность результатов будет зависеть от степени твоей откровенности. Предлагаемые утверждения не являются правильными или неправильными, а лишь констатируют определенные различия в деятельности людей.', 
    options: ['Да ', 'Нет '] },
    { id: 90, title: '88', type: 'yesno', 
    text: 'Порой действую без учета возможных последствий.', 
    options: ['Да', 'Нет'] },
    { id: 91, title: '89', type: 'yesno', 
    text: 'Считаю, что формальные процедуры и правила ограничивают людей.', 
    options: ['Да ', 'Нет '] },
    { id: 92, title: '90', type: 'yesno', 
    text: 'Нравится, когда есть время для тщательной подготовки к работе.', 
    options: ['Да', 'Нет'] },
    { id: 93, title: '91', type: 'yesno', 
    text: 'Предпочитаю принимать решения осторожно, взвесив несколько альтернативных вариантов.', 
    options: ['Да ', 'Нет '] },
    { id: 94, title: '92', type: 'yesno', 
    text: 'Обычно точно знаю, что правильно, а что неправильно, что хорошо, а что плохо.', 
    options: ['Да', 'Нет'] },
    { id: 95, title: '93', type: 'yesno', 
    text: 'Склонен решать проблемы последовательно, продвигаясь шаг за шагом.', 
    options: ['Да ', 'Нет '] },
    { id: 96, title: '94', type: 'yesno', 
    text: 'Ориентирован, в первую очередь, на завершение запланированной работы.', 
    options: ['Да', 'Нет'] },
    { id: 97, title: '95', type: 'yesno', 
    text: 'Придерживаюсь установленных процедур и правил, пока они эффективны.', 
    options: ['Да ', 'Нет '] },
    { id: 98, title: '96', type: 'yesno', 
    text: 'Полагаю, что действия, основанные на чувствах, являются столь же здравыми, как и действия, основанные на обдумывании и анализе.', 
    options: ['Да', 'Нет'] },
    { id: 99, title: '97', type: 'yesno', 
    text: 'Предпочитаю реагировать на события спонтанно и гибко.', 
    options: ['Да ', 'Нет '] },
    { id: 100, title: '98', type: 'yesno', 
    text: 'Уделяю пристальное внимание деталям, прежде чем прийти к какому-либо заключению.', 
    options: ['Да', 'Нет'] },
    { id: 101, title: '99', type: 'yesno', 
    text: 'Осторожен, и не делаю слишком поспешных выводов.', 
    options: ['Да ', 'Нет '] },
    { id: 102, title: '100', type: 'yesno', 
    text: 'Склонен общаться, соблюдая дистанцию, и поддерживать несколько формальные отношения с коллегами.', 
    options: ['Да', 'Нет'] },
    { id: 103, title: '101', type: 'yesno', 
    text: 'Не терплю неупорядоченности и предпочитаю все «увязывать» в согласованные схемы.', 
    options: ['Да ', 'Нет '] },
    { id: 104, title: '102', type: 'yesno', 
    text: 'Новые, интересные идеи стремлюсь немедленно реализовать на практике.', 
    options: ['Да', 'Нет'] },
    { id: 105, title: '103', type: 'yesno', 
    text: 'В обсуждениях стараюсь придерживаться сути дела и избегать пространных рассуждений.', 
    options: ['Да ', 'Нет '] },
    { id: 106, title: '104', type: 'yesno', 
    text: 'Чувствую себя не очень комфортно в обществе спокойных вдумчивых людей.', 
    options: ['Да', 'Нет'] },
    { id: 107, title: '105', type: 'yesno', 
    text: 'Предпочитаю наслаждаться настоящим моментом, не отвлекаясь на мысли о прошлом или будущем.', 
    options: ['Да ', 'Нет '] },
    { id: 108, title: '106', type: 'yesno', 
    text: 'Обычно выслушиваю мнение других людей, прежде чем самому что-либо предпринять.', 
    options: ['Да', 'Нет'] },
    { id: 109, title: '107', type: 'yesno', 
    text: 'В дискуссиях нравится наблюдать за поведением других участников.', 
    options: ['Да ', 'Нет '] },
    { id: 110, title: '108', type: 'yesno', 
    text: 'Склонен соотносить свои действия с некоторыми общими принципами.', 
    options: ['Да', 'Нет'] },
    { id: 111, title: '109', type: 'yesno', 
    text: 'Легкомысленные, несерьезные люди меня обычно раздражают.', 
    options: ['Да ', 'Нет '] },
    { id: 112, title: '110', type: 'yesno', 
    text: 'Меня обычно привлекают конкретные приемы и техники, непосредственно реализуемые на практике.', 
    options: ['Да', 'Нет'] },
    { id: 113, title: '111', type: 'yesno', 
    text: 'Предпочитаю оценивать идеи по практической пользе от них.', 
    options: ['Да ', 'Нет '] },
    { id: 114, title: '112', type: 'yesno', 
    text: 'Новые, необычные идеи меня привлекают в большей степени, чем простые и легко реализуемые на практике.', 
    options: ['Да', 'Нет'] },
    { id: 115, title: '113', type: 'yesno', 
    text: 'Много и ярко говорю.', 
    options: ['Да ', 'Нет '] },
    { id: 116, title: '114', type: 'yesno', 
    text: 'Испытываю дискомфорт, если вынужден выполнять работу в спешке.', 
    options: ['Да', 'Нет'] },
    { id: 117, title: '115', type: 'yesno', 
    text: 'При подготовке доклада, реферата или выступления делаю множество черновых набросков.', 
    options: ['Да ', 'Нет '] },
    { id: 118, title: '116', type: 'yesno', 
    text: 'Меня интересуют общие принципы и теории, подводящие основание под факты и события.', 
    options: ['Да', 'Нет'] },
    { id: 119, title: '117', type: 'yesno', 
    text: 'Предпочитаю, чтобы все мероприятия проводились в соответствии с намеченным планом.', 
    options: ['Да ', 'Нет '] },
    { id: 120, title: '118', type: 'yesno', 
    text: 'Нравятся люди, которые подходят к проблемам с практической, а не с теоретической точки зрения.', 
    options: ['Да', 'Нет'] },
    { id: 121, title: '119', type: 'yesno', 
    text: 'В ходе обсуждения меня обычно раздражают любые отклонения от темы.', 
    options: ['Да ', 'Нет '] },
    { id: 122, title: '120', type: 'yesno', 
    text: 'Быстро надоедает работа, требующая внимательного отношения к деталям', 
    options: ['Да', 'Нет'] },
    { id: 123, title: '121', type: 'yesno', 
    text: 'Испытываю воодушевление от участия в ярких событиях и рискованных ситуациях.', 
    options: ['Да ', 'Нет '] },
    { id: 124, title: '122', type: 'yesno', 
    text: 'Прежде чем действовать, как правило, все тщательно обдумываю.', 
    options: ['Да', 'Нет'] },
    { id: 125, title: '123', type: 'yesno', 
    text: 'В целом охотнее слушаю, чем говорю.', 
    options: ['Да ', 'Нет '] },
    { id: 126, title: '124', type: 'yesno', 
    text: 'Нравится, когда могу соотнести текущие действия с долгосрочной перспективой.', 
    options: ['Да', 'Нет'] },
    { id: 127, title: '125', type: 'yesno', 
    text: 'Стараюсь избегать обсуждения субъективных или неоднозначных тем.', 
    options: ['Да ', 'Нет '] },
    { id: 128, title: '126', type: 'yesno', 
    text: 'Не обращаю внимания на чувства людей, пока работа не закончена.', 
    options: ['Да', 'Нет'] },
    { id: 129, title: '127', type: 'yesno', 
    text: 'Люди часто считают, что я не чувствителен к их переживаниям и эмоциям.', 
    options: ['Да ', 'Нет '] },
    { id: 130, title: '!', type: 'about', 
    text: 'Четвертый блок вопросов направлен на то, чтобы помочь тебе определиться, каким именно ИТ-специалистом ты мечтаешь стать. Мы зададим тебе ряд вопросов, которые помогут выявить твои интересы, навыки и склонности. Ты  узнаешь какие области информационных технологий тебя привлекают, какие задачи и проекты тебя вдохновляют, и какие навыки тебе следует развивать, чтобы достичь успеха в выбранной сфере.', 
    },
    { id: 131, title: '128', type: 'yesno', 
    text: 'Выберите утверждение, которое вам ближе', 
    options: ['Работать над созданием приложений с виртуальной или дополненной реальностью', 
    'Делать красивые и аккуратные сайты из дизайн-макетов',
    'Делать важную, но малозаметную работу: оптимизировать, настраивать и обеспечивать надёжность сайта',
    'Искать ошибки и придумывать способы, как лучше их исправить'] },
    { id: 132, title: '129', type: 'yesno', 
    text: 'Выберите утверждение, которое вам ближе', 
    options: ['Делать мобильное приложение удобным и понятным для пользователя', 
    'Придумывать новые и нетривиальные способы поиска ошибок',
    'Программировать сайты так, чтобы на любых устройствах они отображались корректно',
    'Разносторонне продумывать логику и внутреннюю структуру будущей программы'] },
    { id: 133, title: '130', type: 'yesno', 
    text: 'Выберите утверждение, которое вам ближе', 
    options: ['Создавать плавные переходы и интересные анимации в мобильном приложении', 
    'Уметь делать всю работу целиком - от идеи и до последней строчки кода',
    'Быть специалистом, от внимания которого не ускользнёт ни один сдвинутый пиксель',
    'Внедрять в мобильное приложение аналитику поведения пользователя'] },
    { id: 134, title: '131', type: 'yesno', 
    text: 'Выберите утверждение, которое вам ближе', 
    options: ['Придумывать программы, которые автоматически ищут ошибки', 
    'Активно использовать в программировании микрофон, камеру, геолокацию и другие функции моб. телефона',
    'Делать сайты удобными для людей с ограниченными возможностями',
    'Оформлять внешний вид сайта и создавать его структуру'] },
    { id: 135, title: '132', type: 'yesno', 
    text: 'Выберите утверждение, которое вам ближе', 
    options: ['Уметь абстрагироваться от технического сценария и понять поведение пользователя', 
    'Создавать интересные эффекты и анимации на сайте',
    'Педантично, шаг за шагом, проверять правильность работы каждой детали',
    'Разбираться во всех областях программирования, пусть даже и поверхностно'] },
    { id: 136, title: '133', type: 'yesno', 
    text: 'Выберите утверждение, которое вам ближе', 
    options: ['Программировать различные детали сайта - кнопки, прогресс-бары и другие', 
    'Анализировать разнородные данные и собирать их в единое целое',
    'Делать сайты удобными и понятными для пользователей',
    'Находить даже минимальные расхождения между тем, как должна работать программа, и тем, как она работает сейчас'] },
    { id: 137, title: '134', type: 'yesno', 
    text: 'Выберите утверждение, которое вам ближе', 
    options: ['Понимать бизнес-логику и применять ее в создании сайтов', 
    'Разбираться в программировании не только гаджетов, но и устройств умного дома, научных аппаратов',
    'Сразу видеть результат своей работы',
    'Прописывать четкие и понятные рекомендации для исправления ошибок другим специалистам'] },
    { id: 138, title: '135', type: 'yesno', 
    text: 'Выберите утверждение, которое вам ближе', 
    options: ['Оптимизировать сценарии сайта для ускорения загрузки страниц', 
    'Готов работать в ІТ после нескольких месяцев обучения',
    'Разрабатывать несложные игры для мобильных устройств',
    'Работать в режиме многозадачности'] },
    { id: 139, title: '136', type: 'yesno', 
    text: 'Выберите утверждение, которое вам ближе', 
    options: ['Брать на себя ответственность за функционирование всего сайта', 
    'Настраивать работу слайдеров, кнопок, онлайн-форм и другого функционала сайта',
    'Пользоваться мобильными приложениями и изучать их больше, чем сайты',
    'Старательно проверять все элементы на сайте/ в приложении, заполнять все формы и нажимать на все кнопки'] },
    { id: 140, title: '137', type: 'yesno', 
    text: 'Выберите утверждение, которое вам ближе', 
    options: ['Работать над программированием новых фишек мобильного приложения', 
    'Оживлять статичные страницы',
    'Осваивать и изучать новые технологии из разных сфер программирования',
    'Скрупулезно оптимизировать приложения для меньшего потребления заряда батареи'] },
    { id: 141, title: '138', type: 'yesno', 
    text: 'Выберите утверждение, которое вам ближе', 
    options: ['Четко следовать гайдлайнам (стандартам) различных элементов', 
    'Комфортнее работать одному',
    'Анализировать большой объем однотипной информации',
    'Уметь одинаково хорошо настраивать операционные системы и разрабатывать сайты'] },

  ];
export default questions;